section {
  padding: 40px 20px;
  text-align: center;
}

h1 {
  padding-bottom: 40px;
}

p {
  font-size: 25px;
}

.bottom-button {
  background-color: #5a8f6b;
  color: #ffffff;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.bottom-button:hover {
  background-color: #497255;
  transform: translateY(-3px);
}

/* Links */
.link-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  margin-top: 30px;
}
