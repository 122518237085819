.container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 20px;
}

.projectsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projectCard {
  background-color: #f9f9f9;
  padding: 15px;
  margin: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.techContainer {
  margin: 10px 0;
}

.techBadge {
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0 5px;
  display: inline-block;
}

.projectLink {
  display: block;
  margin-top: 10px;
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.image-container {
  position: relative;
  width: 100%;
  height: 400px; /* Adjust height as needed */
  overflow: hidden;
}

.blurred-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(8px); /* Adjust blur strength */
  -webkit-filter: blur(8px); /* For Safari */
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
}

h1,
p {
  margin: 0;
  font-family: Arial, sans-serif;
}
