.navbar-container {
  display: flex;
  justify-content: space-around;
  background-color: #626b78;
  color: #fff;
  padding: 1rem;
  margin-bottom: 2%;
  flex-direction: row;
}

.navbar-container a:link {
  color: #262720;
}

.navbar-container a:visited {
  color: #ffffff;
}

.navbar-container a:hover {
  color: #728bad;
}

.navbar-container a:active {
  color: black;
}
